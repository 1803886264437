<template>
  <el-footer>
    <div class="wrap">
      <div class="copys">
        Copyright © 2019-2023 安徽鼎沛智云信息技术有限公司   
        <el-link href="https://beian.miit.gov.cn/" target="_blank">备案号：皖ICP备2021000551号</el-link>
      </div>
    </div>
  </el-footer>
</template>

<script>
export default {
  name: 'footerPage',
  data(){
    return{

    }
  }
}
</script>

<style lang="scss" scoped>
.el-footer{
  height: 80px !important;
  background-color: #141a21;
}
.wrap{
  width: 1280px;
  margin: 0 auto;
}
.copys{
  width: 100%;
  height: 80px;
  line-height: 80px;
  font-size: 14px;
  color: #999;
  text-align: center;

  .el-link{
    color: #999;
    height: 16px;
    vertical-align: baseline;
  }

  .el-link.el-link--default:hover{
    color: #fff;
  }
  .el-link.el-link--default::after {
    border-color: #fff;
  }
}
</style>