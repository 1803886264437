<template>
  <div class="affix">
    <div class="qrcord">
      <span>关注我们</span>
      <div class="floating">
        <div class="img">
          <img src="../assets/ewm.jpg" alt="">
        </div>
        <h4>关注****公众号</h4>
      </div>
    </div>
    <el-backtop :bottom="100">
      <div class="return">
        <span>回到顶部</span>
      </div>
    </el-backtop>
  </div>
</template>

<script>
export default {
  name: 'affixPage'
}
</script>

<style lang="scss" scoped>
.affix{
  position: fixed;
  right: 50px;
  bottom: 25%;
  z-index: 10;
}
.qrcord {
  display: block;
  width: 80px;
  height: 80px;
  background: #FFF url(../assets/we.png) no-repeat center 30%;
  background-size: 30px !important;
  font-size: 14px;
  border-radius: 10px;
  cursor: pointer;
  box-shadow: 0px 0px 10px rgba(181, 193, 205, 0.3);

  span{
    display: block;
    padding-top: 50px;
    text-align: center;
    font-size: 12px;
  }
}


.floating{
    width: 200px;
    padding-bottom: 15px;
    background: #FFF;
    position: absolute;
    left: -220px;
    top: 0px;
    box-shadow: 0px 0px 20px 0px rgba(110, 125, 141, 0.1);
    border-radius: 12px;
    display: none;
    overflow: hidden;

    .img{
      width: 130px;
      height: 130px;
      margin: 20px auto 10px;
      display: block;

      img{
        width: 100%;
        height: 100%;
        display: block;
      }
    }
    h4{
      font-size: 14px;
      font-weight: 400;
      color: #14212F;
      text-align: center;
    }
  }
  
.qrcord:hover{
  background-color: #edf4ff;
  color: #3185fe;
}
.qrcord:hover .floating{
  display: block;
}

.return{
  width: 80px;
  height: 80px;
  background: #FFF url(../assets/top.png) no-repeat center 30%;
  background-size: 30px !important;
  font-size: 14px;
  border-radius: 10px;
  cursor: pointer;
  box-shadow: 0px 0px 10px rgba(181, 193, 205, 0.3);
  margin-top: 10px;
  
  span{
    display: block;
    padding-top: 50px;
    text-align: center;
    font-size: 12px;
  }
}

.return:hover{
  background-color: #edf4ff;
  color: #3185fe;
}

:deep .el-backtop {
  width: 80px;
  height: 80px;
  display: block;
  position: static;
  color: #000;
}
</style>