<template>
  <!-- 首页 -->
    <el-container>
      <Header :id="0"></Header>
      <Main :banner="bannerData" :active="activeIndex" :title="title" :acon="aconData" :active1="activeIndex1" :title1="title1" :bcon="bconData" :active2="activeIndex2" :title2="title2" :hd="hdData" :timer1="timer" :dcon="dconData" :title3="title3"></Main>
      <Footer></Footer>
      <Affix></Affix>
    </el-container>
</template>

<script>
import Header from '@/components/header.vue';
import Main from '@/components/main.vue';
import Footer from '@/components/footer.vue';
import Affix from '@/components/affix.vue';

export default {
  name: 'HomePage',
  components:{
    Header,
    Footer,
    Affix,
    Main
  },
  data(){  
    return{
      activeIndex: 0,
      title: '专注移动电商营销 打造全域一体综合经营',
      aconData:[
      {
          title: '移动电商推广系统',
          img: require('../assets/home/a/img1.png'),
          content: '经典推广模式，组合推广玩法，灵活推广管理，构建强大推广体系，助力业绩倍增'
        },
        {
          title: 'B2B2C多商家系统',
          img: require('../assets/home/a/img2.png'),
          content: '支持平台自营与商户入驻共存模式，全渠道多终端覆盖，丰富营销功能、多元盈利模式'
        },
        {
          title: '行业生态SAAS系统',
          img: require('../assets/home/a/img3.png'),
          content: '商城全渠道多终端覆盖，支持多账号运营，打通线上线下营销场景，打造电商运营闭环'
        },
        {
          title: '商城定制开发',
          img: require('../assets/home/a/img4.png'),
          content: '丰富电商项目研发经验，用专业确保企业定制开发项目的可行性，不做盲目开发，上线可用'
        }
      ],
      activeIndex1: 0,
      title1: '多场景电商解决方案 轻松玩转电商营销',
      bconData:[
        {
          id: 4,
          img: require('../assets/home/b/img1.png'),
          tabImg: require('../assets/home/b/1.png'),
          title: '推广解决方案',
          content: '经典推广系统，丰富推广功能，完善推广机制帮你解决获客、分佣、卖货，实现社交新零售的转型，实现社交新零售的转型'
        },
        {
          id: 5,
          img: require('../assets/home/b/img2.png'),
          tabImg: require('../assets/home/b/2.png'),
          title: '电商直播解决方案',
          content: '通过线上直播增强用户信任，实现商家与用户双向互动，开辟新的流量入口，开启线上营销新局面'
        },
        {
          id: 6,
          img: require('../assets/home/b/img3.png'),
          tabImg: require('../assets/home/b/3.png'),
          title: '多商家平台解决方案',
          content: '平台自营+店铺入驻共存的经营模式，套餐式入驻，多元化盈利，赋予平台线上线下整合经营能力'
        },
      ],
      bannerData:[
      require('../assets/home/banner/1.png'),
      require('../assets/home/banner/2.png'),
      require('../assets/home/banner/3.png'),
      require('../assets/home/banner/4.png')
      ],
      activeIndex2: 0,
      title2: '海量业务应用 全方位覆盖电商销售场景',
      hdData:[
        {
          id: 1,
          title: '高效引流变现',
          content: '打破传统电商束缚，通过完善推广推广机制、秒杀、拼团返利等模式，打通线上线下营销场景，实现高效拉新',
          img: require('../assets/home/c/1.png')
        },
        {
          id: 2,
          title: '增强会员粘性',
          content: '通过丰富的优惠奖励模式，增强会员粘性，提升会员复购率，最大程度留存用户，多样化营销玩法促成转化',
          img: require('../assets/home/c/2.png')
        },
        {
          id: 3,
          title: '私域裂变引流',
          content: '充分利用私域用户积累，打造社交裂变，通过不同活动类型引流，如多人拼团、裂变免单，引流人数广，范围广',
          img: require('../assets/home/c/3.png')
        },
        {
          id: 4,
          title: '刺激精准会员',
          content: '礼品卡拓宽社交营销模式，小程序直播实时互动精准营销，积分商城提升会员粘性，多元化的营销应用激活会员',
          img: require('../assets/home/c/4.png')
        },
        {
          id: 5,
          title: '视频直播带货',
          content: '商城通过视频号直播等多种直播带货形式拉新引流，刺激用户购买欲望，公域引流私域，沉淀自有流量',
          img: require('../assets/home/c/5.png')
        }
      ],
      timer: null,
      title3: '全方位覆盖电商销售场景',
     dconData:[
        {
          id: 1,
          title: '专注电商领域',
          content: '专注于电商系统的研发，坚持精雕细琢，致力于为企业打造高品质商城系统',
          img: require('../assets/home/d/1.png')
        },
        {
          id: 2,
          title: '追求客户满意',
          content: '坚持客户满意为第一服务标准，不断追求卓越，满足不同场景下的客户需求',
          img: require('../assets/home/d/2.png')
        },
        {
          id: 3,
          title: '产品精益求精',
          content: '专注于电商系统的研发，坚持精雕细琢，致力于为企业打造高品质商城系统',
          img: require('../assets/home/d/3.png')
        },
        {
          id: 4,
          title: '系统稳定安全',
          content: '专注于电商系统的研发，坚持精雕细琢，致力于为企业打造高品质商城系统',
          img: require('../assets/home/d/4.png')
        },
      ]
    }
  },
}
</script>

<style lang="scss" scoped>
.el-container{
  display: block;
}
</style>