import { render, staticRenderFns } from "./childA.vue?vue&type=template&id=330c8845&scoped=true"
import script from "./childA.vue?vue&type=script&lang=js"
export * from "./childA.vue?vue&type=script&lang=js"
import style0 from "./childA.vue?vue&type=style&index=0&id=330c8845&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "330c8845",
  null
  
)

export default component.exports