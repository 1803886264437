import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '@/views/Home.vue'
import One from '@/views/One.vue'
import Two from '@/views/Two.vue'
import Three from '@/views/Three.vue'
import ChildA from '@/views/components/childA.vue'
import ChildB from '@/views/components/childB.vue'
import ChildC from '@/views/components/childC.vue'
import ChildD from '@/views/components/childD.vue'

Vue.use(VueRouter)

const router = new VueRouter({
  mode: 'history',
  routes:[
    {
      path: '/',
      name: 'Home',
      component: Home
    },
    {
      path: '/one',
      name: 'One',
      component: One
    },
    {
      path: '/two',
      name: 'Two',
      component: Two
    },
    {
      path: '/three',
      name: 'Three',
      component: Three
    },
    {
      path: '/childA',
      name: 'ChildA',
      component: ChildA
    },
    {
      path: '/childB',
      name: 'ChildB',
      component: ChildB
    },
    {
      path: '/childC',
      name: 'ChildC',
      component: ChildC
    },
    {
      path: '/childD',
      name: 'ChildD',
      component: ChildD
    },
]
})

export default router