<template>
        <el-header>
        <div class="topbar-wrapper">
          <div class="topbox">
            <div class="imgA">
              <el-link :underline="false">
                <img class="img" src="../assets/logo.png" alt="">
              </el-link>
            </div>
            <ul class="service">
              <li v-for="(item,index) in titleData" :key="item.id">
                <router-link :target="index == active || index == 1 || index == 0 ? '' : '_blank'" :to="item.path" class="show" :class="index == active ? 'active' : ''">
                  {{item.name}}
                  <img v-if="item.id == 2" class="jiantou1" style="width: 12px;" src="../assets/top/bottom.png" alt="">
                  <img v-if="item.id == 2" class="jiantou2" style="width: 12px;" src="../assets/top/top.png" alt="">
                </router-link>
                <div class="info" v-if="item.id == 2">
                  <div class="info_content">
                    <div class="info_text" v-for="(items,indexs) in item.child" :key="indexs">
                      <a :href="items.path" target="_blank">
                        <p class="p_one">
                          <img class="img" :src="items.img" alt="">
                          {{items.h}}</p>
                        <p class="p_two">{{items.text}}</p>
                      </a>
                    </div>
                  </div>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </el-header>
</template>

<script>
export default {
  name: 'headerPage',
  props:['id'],
  data(){
    return{
      titleData:[
        {
          id: 1,
          name: '首页',
          path: '/'
        },
        {
          id: 2,
          name: '解决方案',
          path: '',
          child: [
            {
              path: '/childA',
              img: require('../assets/top/1.png'),
              h: '成熟推广解决方案',
              text: '经典推广模式，组合推广玩法构建一体化推广解决方案'
            },
            {
              path: '/childB',
              img: require('../assets/top/2.png'),
              h: '连锁门店解决方案',
              text: '打通线上线下联动购物生态，助力品牌开拓全新业务场景'
            },
            {
              path: '/childC',
              img: require('../assets/top/3.png'),
              h: '多商家平台解决方案',
              text: '多模式平台级智慧电商生态系统，轻松玩转多场景电商'
            },
            {
              path: '/childD',
              img: require('../assets/top/4.png'),
              h: '社交电商解决方案',
              text: '基于微信等社交媒介用户流量开启社交营销新赛道盘活流量'
            }
          ]
        },
        {
          id: 3,
          name: '企业服务',
          path: '/one'
        },
        {
          id: 4,
          name: '服务&帮助',
          path: '/two'
        },
        {
          id: 5,
          name: '关注我们',
          path: '/three'
        },
      ],
      active: 0
    }
  },
  mounted(){
    this.active = this.id
  },
  created(){
    // if(this.active == 0){
    //   this.$router.go(0)
    // }
  }

}
</script>

<style scoped lang="scss">
.el-header{
  width: 100%;
  height: 80px !important;
  padding: 0;
  position: fixed;
  left: 0;
  top: 0;
  z-index: 9999;
  transition: all 0.5s;
  /* padding: 0 48px; */
  box-sizing: border-box;
}
.topbar-wrapper{
    width: 100%;
    height: 80px;
    line-height: 80px;
    background-color: #fff;
    font-size: 16px;
}

.topbox{
    height: 80px;
    margin: 0 auto;
    display: flex;
    justify-content: center;
}

.imgA{
    margin: 10px 0;
}

.img{
    width: 60px;
    height: 60px;
}

.service{
    margin-left: 100px;
}

.service,ul{
  margin-top: 0;
}

.service li{
    padding-right: 50px;
    float: left;
}

.service li a:hover {
    color: #3185FE;
}
.service li a.active {
    color: #3185FE;
}

.service .info {
    height: 0;
    overflow: hidden;
    width: 100%;
    background-color: #eef5ff;
    position: absolute;
    top: 80px;
    left: 0px;
    transition: height 0.3s;
    z-index: 999999;
}

.service .show{
  position: relative;
}

.service .show .jiantou2{
  display: none;
}

.service .show .jiantou1{
  display: block;
}

.service .show .jiantou1,.service .show .jiantou2{
    position: absolute;
    top: 8px;
    right: -16px;
}

.service .show:hover .jiantou1{
  display: none;
}

.service .show:hover .jiantou2{
  display: block;
}

.service .show:hover~.info,
.info:hover{
    border-top: 1px solid rgb(224, 224, 224);
    box-shadow: 0 3px 3px rgba(0, 0, 0, .2);
    height: 350px;
}

.info{

.info_content{
  width: 800px;
  height: 268px;
  margin: 40px auto 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;

  .info_text{
    width: 49%;
    height: 120px;
    font-size: 14px;
    background: rgba(255, 255, 255, 0.60);
    border-radius: 5px;
    cursor: pointer;
    
    .p_one{
      margin: 0;
      font-size: 18px;
      font-weight: 700;
      margin: 10px 20px 0;
      line-height: 40px;

      .img{
        width: 40px;
        height: 40px;
      }
    }

    .p_two{
      line-height: 20px;
      margin: 0;
      margin-left: 60px;
      margin-right: 20px;
      color: #566473;
    }
  }

  .info_text:hover .p_one{
    color: #3185FE;
  }
}

}
</style>