<template>
  <el-main>
    <div class="block">
      <el-carousel height="500px">
        <el-carousel-item v-for="item in bannerData" :key="item">
          <img style="width: 100%;height: 100%;" :src="item" alt="">
        </el-carousel-item>
      </el-carousel>
    </div>
    <div class="block1">
      <div class="a_content">
        <h1 class="slide-up-text">{{ titleA }}</h1>
        <div class="a_cons">
          <div class="a_span">
            <span class="slide-up-text1" :class="activeIndex === index ? 'active' : ''" v-for="(item,index) in aconData" :key="index" @click="butClick(index)">{{item.title}}</span>
          </div>
          <div class="a_next" :class="activeIndex === index ? '' : 'a_show'" v-for="(item,index) in aconData" :key="index">
            <img :src="item.img" alt="">
            <div class="a_subtitle">
              <h2>{{ item.title }}</h2>
              <p>{{ item.content }}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="block2">
      <div class="b_content">
        <h1 :class="class1" ref="scroll1">{{ titleB }}</h1>
        <div class="tabs" :class="class2" ref="scroll2">
          <el-link :class="activeIndex1 === index ? 'active1' : ''" class="link" @click="linkClick(index)" :underline="false" v-for="(item,index) in bconData" :key="item.id">
            <img :src="item.tabImg" alt="">
            <p>{{ item.title }}</p>
          </el-link>
          <div class="swiper-container">
            <div class="b_next swiper-slide" :class="activeIndex1 === index ? '' : 'b_show'" v-for="(item,index) in bconData" :key="item.id">
              <div class="b_subtitle">
                <h2>{{ item.title }}</h2>
                <p>{{ item.content }}</p>
              </div>
              <img :src="item.img" alt="">
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="block3">
      <div class="c_content">
        <h1 :class="class3" ref="scroll3">{{ titleC }}</h1>
        <div class="c_warp">
          <div class="left">
            <ul>
              <li :class="activeIndex2 === index ? 'on' : ''" v-for="(item,index) in hdData" :key="item.id">
                <a href="javascript:void(0)" @mouseenter="hdHover(index)" @mouseleave="hdLeave">{{ item.title }}</a>
              </li>
            </ul>
          </div>
          <div class="right">
            <div :class="class4" ref="scroll4">
              <div class="c_next" v-for="(item,index) in hdData" :key="item.id" :class="activeIndex2 === index ? '' : 'c_show'">
                <div class="c_text">
                  <h2>{{ item.title }}</h2>
                  <p>{{ item.content }}</p>
                </div>
                <img :src="item.img" alt="">
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="block4">
      <div class="d_content">
        <h1 :class="class5" ref="scroll5">{{ titleD }}</h1>
        <div class="d_warp" :class="class6" ref="scroll6">
          <div class="d_con" v-for="item in dconData" :key="item.id">
            <img :src="item.img" alt="">
            <div class="d_text">
              <h2>{{ item.title }}</h2>
              <p>{{ item.content }}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </el-main>
</template>

<script>
export default {
  name: 'mainPage',
  props: ['active', 'active1', 'active2', 'acon', 'bcon', 'hd', 'dcon', 'timer1', 'banner', 'title', 'title1', 'title2', 'title3',],
  data(){
    return{
      titleA: '',
      titleB: '',
      titleC: '',
      titleD: '',
      activeIndex: 0,
      activeIndex1: 0,
      activeIndex2: 0,
      aconData: [],
      bconData: [],
      hdData: [],
      dconData: [],
      bannerData: [],
      timer: null,
      flag: false,
      class1: '',
      class2: '',
      class3: '',
      class4: '',
      class5: '',
      class6: '',
    }
  },
  created(){
    // this.timer = null
    clearInterval(this.timer)
    this.timer = setInterval(()=>{
      this.activeIndex2 ++ 
      if(this.activeIndex2 == this.hdData.length){
        this.activeIndex2 = 0
      }
    }, 4000)
  },
  mounted() {
    this.titleA = this.title
    this.titleB = this.title1
    this.titleC = this.title2
    this.titleD = this.title3
    this.activeIndex = this.active
    this.activeIndex1 = this.active1
    this.activeIndex2 = this.active2
    this.aconData = this.acon
    this.bconData = this.bcon
    this.hdData = this.hd
    this.dconData = this.dcon
    this.bannerData = this.banner
    this.timer = this.timer1

    window.addEventListener('scroll', this.handleScroll)
  },
  methods:{
    handleScroll(){
      if (this.$refs.scroll1.getBoundingClientRect().y < window.innerHeight) {
        this.class1 = 'animate__animated animate__fadeInUp'
      }
      if (this.$refs.scroll2.getBoundingClientRect().y < window.innerHeight) {
        this.class2 = 'animate__animated animate__fadeInUp'
      }
      if (this.$refs.scroll3.getBoundingClientRect().y < window.innerHeight) {
        this.class3 = 'animate__animated animate__fadeInUp'
      }
      if (this.$refs.scroll4.getBoundingClientRect().y < window.innerHeight) {
        this.class4 = 'animate__animated animate__fadeInRight'
      }
      if (this.$refs.scroll5.getBoundingClientRect().y < window.innerHeight) {
        this.class5 = 'animate__animated animate__fadeInUp'
      }
      if (this.$refs.scroll6.getBoundingClientRect().y < window.innerHeight) {
        this.class6 = 'animate__animated animate__fadeInUp'
      }
    },
    butClick(index){
      this.activeIndex = index
    },
    linkClick(index){
      this.activeIndex1 = index
    },
    hdHover(index){
      this.activeIndex2 = index
      clearInterval(this.timer)
    },
    hdLeave(){
      clearInterval(this.timer)
      this.timer = null
      this.timer = setInterval(()=>{
        this.activeIndex2 ++ 
        if(this.activeIndex2 == this.hdData.length){
          this.activeIndex2 = 0
        }
      }, 4000)
    }
  }
}

</script>

<style lang="scss" scoped>
.el-main{
  padding: 80px 0 0;
}

  .el-carousel__item:nth-child(2n) {
     background-color: #99a9bf;
  }
  
  .el-carousel__item:nth-child(2n+1) {
     background-color: #d3dce6;
  }


/* 定义关键帧动画 */
@keyframes slideUp {
  from {
    transform: translateY(100%);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
}
@keyframes slideRight {
  from {
    transform: translateX(100%);
    opacity: 0;
  }
  to {
    transform: translateX(0);
    opacity: 1;
  }
}
 
/* 应用动画到元素 */
.slide-up-text {
  animation: slideUp 1s ease-out forwards;
}
.slide-up-text1 {
  animation: slideUp 1.2s ease-out forwards;
}
.slide-up-text2 {
  animation: slideRight 1s ease-out forwards;
}

  .block1{
    padding: 70px 0;
    background-color: #f5f9fe;
    .a_content{
      width: 1280px;
      // height: 400px;
      margin: 0 auto;

      h1{
        font-size: 36px;
        text-align: center;
        margin-top: 0;
      }

      .a_cons{
        padding-top: 10px;
        .a_span{
          width: 840px;
          margin: 0 auto;
          margin-bottom: 40px;
          display: flex;
          justify-content: space-between;
        }
        span{
          width: 210px;
          height: 48px;
          font-size: 18px;
          line-height: 47px;
          text-align: center;
          cursor: pointer;
        }
        span.active{
          color: #fff;
          background-color: #3185FE;
          border: 1px solid #fff;
          box-shadow: 0px 0px 10px 1px rgba(68, 68, 68, 0.08);
          border-radius: 32px;
          box-sizing: border-box;
        }
      }

      .a_next{
        padding: 0 147px;
        display: flex;
        img{
          width: 190px;
          height: 385px;
          margin-right: 60px;
        }

        .a_subtitle{
          h2{
            font-size: 28px;
            font-weight: 700;
            margin-bottom: 0;
          }

          p{
            line-height: 28px;
          }
        }
      }
      .a_show{
        display: none;
      }
      
    }
  }

  .block2{
    padding: 70px 0;
    background-color: #0b7bf0;
    .b_content{
      width: 1280px;
      margin: 0 auto;
      h1{
        font-size: 36px;
        text-align: center;
        margin-top: 0;
        color: #fff;
      }

      .tabs{
        text-align: center;
        
        .link{
          margin: 0 70px;
          padding: 0 10px 20px 10px;
          font-size: 18px;
          color: #fff;
          font-weight: 600;
          img{
            width: 66px;
            height: 66px;
          }
          
        }
        .active1{
          color: #000;
        }
        .b_next{
          padding: 0 100px;
          display: flex;
          color: #fff;
          text-align: left;

          img{
            width: 560px;
            height: 330px;
            border-radius: 20px;
          }

          .b_subtitle{
            padding-right: 100px;
            h2{
              font-size: 28px;
              font-weight: 700;
              margin-bottom: 0;
            }

            p{
              line-height: 28px;
            }
          }
        }
        .b_show{
          display: none;
        }
      }
    }
  }

  .block3{
    width: 100%;
    padding: 70px 0 70px 0;
    background: url(../assets/yewu-bg.png) no-repeat center;
    background-size: cover;

    .c_content{
      width: 1280px;
      margin: 0 auto;
      h1{
        font-size: 36px;
        text-align: center;
        margin-top: 0;
      }

      .c_warp{
        width: 1280px;
        margin: 0 auto;
        display: flex;

        .left{
          flex: 1;
          background-color: #f3f8fe;
          padding: 57px 0;
          border-top-left-radius: 20px;
          border-bottom-left-radius: 20px;
          box-sizing: border-box;
          float: left;

          ul{
            padding: 0;
          }

          li{
            width: 152px;
            height: 58px;
            line-height: 56px;
            text-align: center;
            margin: 0 auto;
            margin-bottom: 20px;
            display: block;

            a{
              font-size: 18px;
              font-weight: 500;
              color: #333333;
              border-radius: 10px;
              display: block;
              background: #FFFFFF;
              position: relative;


            }
          }

          li.on a{
            background-color: #3185fe;
            color: #fff;
          }

          .on a::after{
            content: '';
            width: 25px;
            height: 56px;
            background: url(../assets/hover.png) no-repeat;
            background-size: cover;
            position: absolute;
            right: -15px;
          }
        }

        .right{
          flex: 4;
          background-color: #fff;
          padding: 60px;
          border-top-right-radius: 20px;
          border-bottom-right-radius: 20px;

          .c_next{
            display: flex;
          }
          .c_show{
            display: none;
          }
          .c_text{
            padding-right: 100px;
            h2{
              font-size: 28px;
              font-weight: 700;
              margin-bottom: 0;
            }

            p{
              line-height: 28px;
            }
          }
          img{
            width: 300px;
            height: 385px;
          }
        }
      }
    }
  }

  .block4{
    padding: 70px 0;
    background-color: #f5f9fe;
    .d_content{
      width: 1280px;
      margin: 0 auto;

      h1{
        font-size: 36px;
        text-align: center;
        margin-top: 0;
      }
    }
    .d_warp{
      width: 1080px;
      margin: 0 auto;
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;

      .d_con{
        display: flex;
        justify-content: space-between;
        width: 49%;
        padding: 40px 30px;
        margin-top: 20px;
        background-color: #fff;
        border-radius: 20px;
        border: 1px solid #F1F2F3;
        cursor: pointer;

        img{
          width: 70px;
          height: 70px;
          margin-right: 20px;
        }
        .d_text{
          h2{
              font-size: 22px;
              font-weight: 700;
              margin: 0;
            }

            p{
              line-height: 24px;
              color: #566473;
              margin: 0;
              margin-top: 5px;
            }
        }
      }

      .d_con:hover{
        transform: translateY(-10px);
        box-shadow: 0 0 10px rgba(0, 0, 0, .1);
      }
    }
  }
</style>